main {
  position: relative;
  display: grid;
  align-content: center;
  
}

.background-main {
  width: 100vw;
}

.buttons-container-main {
  position: absolute;
  z-index: 1;
  top: 75%;
  left: 18%;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15%;
}

.buttons-container-main,
.button-artist-main,
.button-venue-main {
  width: 30vw;
}

/* Target 500px */
@media screen and (max-width: 500px) {
  .buttons-container-main,
  .button-artist-main,
  .button-venue-main {
    width: 40vw;
    left: 8%;
    transform: translateY(-8px);
  }
}

.sign-up-container {
  color: var(--white);

  display: grid;
  grid-template-rows: repeat(1, 1fr);

  position: absolute;
  top: 89%;
  left: 12%;
  z-index: 1;

  font-size: 8vh;
  /* border-bottom: 1px solid var(--grey); */
  padding-bottom: 10vh;
}

.sign-up-container .sign-up-text {
  width: 80vw;
  font-size: 85%;
  display: grid;
  place-content: center;
  text-align: center;
}

@media screen and (max-width: 540px) {
  .sign-up-container {
    left: 25%;
  }
  .sign-up-container .sign-up-text {
    width: 50vw;
    font-size: 90%;
  }
}

@media screen and (max-width: 725px) {
  .sign-up-container {
    font-size: 5vh;
    left: 28%;
  }
  .sign-up-container .sign-up-text {
    width: 50vw;
    /* font-size: 65%; */
  }
}

@media screen and (max-width: 912px) {
  .sign-up-container {
    left: 16%;
  }
  .sign-up-container .sign-up-text {
    width: 70vw;
    font-size: 75%;
    display: grid;
  }
}
