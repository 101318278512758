footer {
  background-repeat: no-repeat;
  opacity: 1;
}

/* FEATURE GRID  */
.featured-title {
  display: grid;
  justify-content: center;
  padding-bottom: 5%;
}

.list__section {
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  place-content: center;
  gap: 20px;
}

.container {
  position: relative;
}

.image {
  opacity: 1;
  border-radius: 20px;
  border: 5px solid var(--white);
  width: 250px;
  height: 250px;
  transition: 0.5s ease;
  backface-visibility: hidden;
  object-fit: cover;
}

/* FOR THE VENUE-MODAL  */
.middle {
  width: 95%;
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.middle button {
  background-color: transparent;
  color: var(--grey);

  font-family: var(--font-family);
  font-size: 1rem;

  border: 1px solid var(--grey);
  border-radius: 10px;

  padding: 5px 10px;
  margin-top: 10px;
}

.middle button:hover {
  color: var(--pink-red);
  border: 1px solid var(--pink-red);
}

.container:hover .image {
  opacity: 0.5;
}

.container:hover .middle {
  opacity: 0.8;
  color: var(--pink-red);
}

.text {
  background-color: rgba(0, 0, 0, 0.705);
  color: white;
  font-size: 15px;
  padding: 20px 10px;
  border-radius: 8px;
}

.venue_name {
  font-size: 2rem;
}

.venue_address {
  font-size: 1rem;
}

/* SEARCH MORE BUTTON */
.search-more-button {
  width: 40vw;
}

/* Target 500px wide */
@media screen and (max-width: 500px) {
  .search-more-button {
    width: 80vw;
  }
}

.search-more-btn-container {
  padding-top: 50px;
  display: grid;
  justify-content: center;
}

.search-more-btn-container a {
  display: flex;
  align-items: flex-end;
}

.button-hover {
  display: none;
}

.search-more-btn-container a:hover img.no-hover {
  display: none;
}

.search-more-btn-container a:hover img.button-hover {
  display: unset;
}

/* LINK SECTION */
.symbol-link-section {
  margin-left: 20px;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 30% 1fr;
}

.block-social {
  display: grid;
  grid-auto-flow: row;
  place-items: center;
  justify-content: center;
}

.fanflex-symbol-footer img {
  width: 25vw;
  /* object-fit: cover; */
  margin-left: 50%;
  transform: translateX(-50%);
}

/* Target 500px */
@media screen and (max-width: 500px) {
  .fanflex-symbol-footer img {
    width: 50vw;
  }
}

.social-links {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  gap: 1vw;
  font-family: "Roboto", sans-serif;
}

.social-links img {
  width: 4vw;
  object-fit: cover;
}

@media screen and (max-width: 1037px) {
  .links-part {
    margin-top: 0;
    margin-bottom: 30px;
  }

  .links-part h4 {
    font-size: 1.8rem;
  }

  .footer-links {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 500px) {
  .social-links {
    margin-top: 1rem;
  }

  .social-links img {
    width: 10vw;
  }
}

.social-links a:hover {
  transition: 0.5s ease;

  transform: scale(1.11);
}

.links-part {
  margin-left: 8vw;
  margin-top: auto;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 30% 50% 50%;
}

.links-part h4 {
  color: var(--grey);
  font-size: 2.2rem;
  margin-bottom: 30px;
  font-family: var(--alternative-font);
}

.footer-links {
  font-size: 3rem;
  color: var(--grey);
  display: grid;
  gap: 10px;
  padding-right: 20px;
  font-family: var(--alternative-font);
}

.footer-links ul {
  list-style: none;
}
.footer-links ul li {
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  gap: 10px;
}

/* Target not last child */
.footer-links ul li:not(:last-child) {
  margin-bottom: 12px;
}

.footer-links ul li .chevron {
  font-size: 18px;
}

.footer-links a {
  font-family: var(--alternative-font);
  font-size: 25px;
}

.footer-links ul li:hover,
.footer-links ul li:hover a {
  color: var(--pink-red);
}

.monarchy-logo {
  margin: 10px;
  display: grid;
  justify-content: center;
}

/* MEDIA QUERIES */
@media screen and (max-width: 484px) {
  .featured-title {
    font-size: 8vh;
    padding-top: 15%;
  }

  .image {
    border: 3px solid var(--white);
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    object-position: center;
  }

  .list__section {
    grid-template-columns: repeat(auto-fit, 300px);
  }

  .links-part {
    margin: 0 auto;
    max-width: 90%;
  }

  .block-quick {
    width: 100%;
  }

  .block-quick h4 {
    margin-top: 0 !important;
  }

  .block-press .footer-links ul {
    max-width: 85%;
    margin: 0 auto;
  }
  .block-press .footer-links ul li {
    grid-template-columns: max-content 1fr;

    text-align: center;
  }
}

@media screen and (max-width: 875px) {
  /* LAST SECTION OF FOOTER WITH THE LINKS */
  .featured-title {
    padding-top: 5%;
  }

  .symbol-link-section {
    margin-left: 0px;
    grid-template-columns: repeat(1, 1fr);
    place-content: center;
  }

  .links-part {
    width: 100%;
    grid-template-columns: 1fr;
    place-items: center;
    margin-bottom: 20px;
    place-items: start;
    text-align: center;
  }

  .links-part h4 {
    margin-bottom: 10px;
    margin-top: 35px;
  }
}

@media screen and (max-width: 912px) {
  .featured-title {
    font-size: 8vh;
    padding-top: 5%;
  }
}

@media screen and (max-width: 1147px) {
  .links-part {
    margin-top: 60px;
    margin-bottom: 30px;
  }

  .links-part h4 {
    font-size: 2rem;
  }

  .footer-links {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1330px) {
  .list__section {
    grid-template-columns: repeat(4, 250px);
  }
}

@media screen and (max-width: 1380px) {
  .footer-links {
    font-size: 2rem;
    padding: 0;
  }
}

@media screen and (max-width: 1639px) {
  .footer-links {
    font-size: 2.6rem;
  }
}
