* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	scroll-behavior: smooth;
}

html {
	--white: #fffcf2;
	--black: #242424;

	--pink-red: #fe125a;
	--purple: #a41f4e;
	--grey: #ccc5b9;
	--brown: #50493e;
	--font-family: 'Prater Sans', sans-serif;

	scroll-behavior: smooth;
	background-color: var(--black);
}

/* GENERAL ELEMENTS */

svg {
	font-family: var(--font-family);
}

a {
	font-family: var(--font-family);
	cursor: pointer;
	text-decoration: none;
	color: var(--grey);
}

.buttons-container a {
	display: flex;
	align-items: flex-end;
}

.button-hover {
	display: none;
}

.buttons-container a:hover img.no-hover {
	display: none;
}

.buttons-container a:hover img.button-hover {
	display: unset;
}

h3 {
	color: white;
	font-size: 5rem;
}
